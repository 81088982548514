import fortuneWheelBtnImg from "@assets/fw-btn-bg.png";
import { useTranslate } from "@lobby/ocb-intl";
import { Link } from "@tanstack/react-router";

import { FortuneWheel } from "@entities/fortune-wheel";
import { formatTime, useFortuneWheelCountdown } from "@shared/lib";

export function FortuneWheelButton() {
  const { $t } = useTranslate();
  const { data: stateData } = FortuneWheel.useWheelState();
  const { data: settingsData } = FortuneWheel.useWheelSettings();

  const countdown = useFortuneWheelCountdown(stateData?.nextSpinTime as number);

  const isCountdownActive = countdown > 0;

  if (settingsData?.error) {
    return null;
  }

  return (
    <div className="fortune-wheel-btn mt-0 rounded-sm overflow-visible">
      <div className="fortune-wheel-btn__background-layer flex items-center justify-between gap-4 h-full p-3">
        <hr className="border-none bg-gradient-to-r from-transparent via-science-blue to-transparent absolute top-0 left-0 h-0.5 w-full dark:[background-image:linear-gradient(to_right,transparent,#fff,transparent)]" />
        <hr className="border-none bg-gradient-to-r from-transparent via-science-blue to-transparent absolute bottom-0 left-0 h-0.5 w-full dark:[background-image:linear-gradient(to_right,transparent,#fff,transparent)]" />

        <div className="relative min-w-24 size-24">
          <img
            className="absolute object-cover size-full animate-step-spin"
            src={fortuneWheelBtnImg}
            alt="fortune-wheel-preview"
            width="100%"
            height="100%"
          />
        </div>

        <Link
          className="gradient-primary border-2 rounded-sm border-white grow py-1 px-2.5 min-w-44 dark:drop-shadow-[0_0_0.8125rem_#3895f1]"
          to="/fortune-wheel"
        >
          <div
            className={`font-bold leading-5 italic uppercase text-center text-white [text-shadow:0_0_0.625rem_#13c7bc] whitespace-normal *:landscape:inline-block ${isCountdownActive ? "text-lg landscape:text-2xl" : "landscape:text-lg"}`}
          >
            {isCountdownActive ? (
              formatTime(countdown)
            ) : (
              <>
                <div>{$t({ defaultMessage: "fortune wheel" })}</div>{" "}
                <div>{$t({ defaultMessage: "ready to spin" })}</div>
              </>
            )}
          </div>
        </Link>
      </div>
    </div>
  );
}
