import { apiClient, type ApiClientResponse } from "@lobby/api-client";
import { redirect } from "@tanstack/react-router";

import { queryClient } from "@app/config";

export async function ensureUserIsAuthenticated() {
  const data = await queryClient.ensureQueryData<ApiClientResponse<"Player.getCurrent">>({
    queryKey: ["Player.getCurrent"],
    queryFn: () =>
      apiClient.send({
        endpoint: "Player.getCurrent",
        params: { isActive: "true" },
      }),
  });

  return Boolean(data.result);
}

export function isLobbyDemoMode() {
  return import.meta.env.VITE_IS_DEMO === "true";
}

export async function redirectIfUserIsAuthenticated(
  to: string,
  opts?: Parameters<typeof redirect>,
) {
  const isAuthenticated = await ensureUserIsAuthenticated();
  if (isAuthenticated) {
    throw redirect({
      to,
      ...opts,
    });
  }
}

export async function redirectIfAuthenticatedUserRequired(opts?: Parameters<typeof redirect>[0]) {
  const isAuthenticated = await ensureUserIsAuthenticated();
  if (!isAuthenticated) {
    throw redirect({
      to: "/sign-in",
      replace: true,
      ...opts,
    });
  }
}

export async function redirectIfFortuneWheelIsNotAvailable(opts?: Parameters<typeof redirect>[0]) {
  const data = await queryClient.ensureQueryData<ApiClientResponse<"Lobby.getCurrent">>({
    queryKey: ["Lobby.getCurrent"],
    queryFn: () =>
      apiClient.send({
        endpoint: "Lobby.getCurrent",
      }),
  });

  const isFortuneWheelAvailable = Boolean(data.result?.fortuneWheel?.isActive);
  if (!isFortuneWheelAvailable) {
    throw redirect({
      to: "/",
      replace: true,
      ...opts,
    });
  }
}
