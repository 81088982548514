import { useTranslate } from "@lobby/ocb-intl";
import { clsx } from "clsx";
import { useContext, useEffect, useRef, useState } from "react";

import { Player, PlayerSettingsContext, usePlayerMoneyFormatter } from "@entities/player";
import { PlayerBalance } from "@features/player/player-balance";
import { emitter } from "@shared/lib";
import { Skeleton, SVGIcon } from "@shared/ui";

import { TPlayerBalancesEx } from "@lobby/core/shared/lib";
import type { PropsWithChildren } from "react";

export function ProfilePlayerBalanceView({ className }: { className?: string }) {
  const [isDummyFetching, setIsDummyFetching] = useState(false);
  const loadingIndicatorRef = useRef<HTMLDivElement>(null);

  const { $t } = useTranslate();

  const playerSettings = useContext(PlayerSettingsContext);

  const { data: player } = Player.usePlayer();
  const { data: balanceData, refetch, isFetching } = Player.useBalances();

  const isPending = isFetching || isDummyFetching;
  const balance = balanceData?.balance ?? ({} as TPlayerBalancesEx["balance"]);

  /*
    Regular balance - only balance field is present
    SS Dual balance - balance and win fields are present
    SS Single balance - all fields are present

    @link https://lobby-v2.stage.c23.games/api-docs/#/Player/Player.getBalancesEx
  */
  const isRegularBalance = Object.keys(balance).length === 1;

  function toggleBalanceVisibility() {
    emitter.emit("PLAYER_BALANCE_VISIBILITY_CHANGED", !playerSettings.isBalanceVisible);
  }

  const refetchBalances = async () => {
    if (isPending) return;

    setIsDummyFetching(true);
    await refetch();
    setTimeout(() => setIsDummyFetching(false), 1000);
  };

  useEffect(() => {
    const loadingIndicator = loadingIndicatorRef.current!;

    if (isPending) {
      loadingIndicator.classList.add("animate-spin");
    }
  }, [isPending]);

  return (
    <div
      className={clsx(
        className,
        "lg:rounded-sm lg:p-5 bg-athens-gray rounded-xs p-3 dark:bg-mine-shaft",
      )}
    >
      <div className="flex-c-sb gap-5">
        <div className="lg:text-base text-lg font-bold truncate">
          {String(player?.displayableName)}
        </div>
        <div
          ref={loadingIndicatorRef}
          className="cursor-pointer"
          onClick={refetchBalances}
          onAnimationIteration={(e) =>
            !isPending && e.currentTarget.classList.remove("animate-spin")
          }
        >
          <SVGIcon className="text-lg" name="reload" />
        </div>
      </div>

      <div className="flex-c-sb gap-3 mt-5">
        <div className="flex items-center gap-1 text-science-blue font-bold uppercase dark:text-dodger-blue">
          <span>{$t({ defaultMessage: "Balance" })}</span>
          <button className="p-1" type="button" onClick={toggleBalanceVisibility}>
            {playerSettings.isBalanceVisible ? (
              <SVGIcon name="openedEye" />
            ) : (
              <SVGIcon name="closedEye" />
            )}
          </button>
        </div>
        <BalanceValue className="!h-7 !w-32" loading={playerSettings.isBalanceVisible && isPending}>
          <PlayerBalance className="lg:text-lg font-bold" />
        </BalanceValue>
      </div>

      {!isRegularBalance && <hr className="border-dusty-gray my-2 dark:border-silver" />}

      {isRegularBalance && (
        <div className="lg:text-sm text-xs text-big-stone space-y-1 dark:text-white">
          {"credit" in balance && (
            <BalanceRow
              title={$t({ defaultMessage: "Credit" })}
              value={balance.credit as number}
              loading={isPending}
            />
          )}
          {"bonus" in balance && (
            <BalanceRow
              title={$t({ defaultMessage: "Bonus" })}
              value={balance.bonus as number}
              loading={isPending}
            />
          )}
          {"win" in balance && (
            <BalanceRow
              title={$t({ defaultMessage: "Total win" })}
              value={balance.win as number}
              loading={isPending}
            />
          )}
          {"redeem" in balance && (
            <BalanceRow
              title={$t({ defaultMessage: "Redeemable" })}
              value={balance.redeem as number}
              loading={isPending}
            />
          )}
        </div>
      )}
    </div>
  );
}

interface IBalanceRowProps {
  title: string;
  value: number;
  loading: boolean;
}

function BalanceRow({ title, value, loading }: IBalanceRowProps) {
  const formatMoney = usePlayerMoneyFormatter();

  return (
    <div className="flex-c-sb gap-2">
      <span className="font-bold whitespace-nowrap">{title}</span>
      <span className="grow border-b-2 border-dotted border-dusty-gray/50 -mb-2 min-w-10" />
      <BalanceValue loading={loading}>{formatMoney(value)}</BalanceValue>
    </div>
  );
}

function BalanceValue({
  className,
  loading,
  children,
}: PropsWithChildren<{ className?: string; loading: boolean }>) {
  return loading ? (
    <Skeleton
      className={clsx("lg:rounded-rounded rounded-sm lg:h-5 h-4 w-20 dark:bg-shark", className)}
    />
  ) : (
    <span className="truncate">{children}</span>
  );
}
