import { useTranslate } from "@lobby/ocb-intl";
import { useQueryClient } from "@tanstack/react-query";
import { useNavigate } from "@tanstack/react-router";
import { useEffect, useState } from "react";

import { FortuneWheel as FortuneWheelContext } from "@entities/fortune-wheel";
import { usePlayerMoneyFormatter } from "@entities/player";
import { CongratsModal } from "@features/congrats-modal";
import { emitter, useMobile } from "@shared/lib";
import { Button } from "@shared/ui";
import { Footer } from "@widget/footer";
import { FortuneWheel } from "@widget/fortune-wheel";
import { repeatSectors, WHEEL_SECTORS_REPEAT_COUNT, type TSector } from "@widget/fortune-wheel/lib";
import { Header } from "@widget/header";

import { RulesSectionDesktop } from "./rules-section.desktop";
import { RulesSectionMobile } from "./rules-section.mobile";

import type { TFortuneWheelSectorParams } from "@widget/fortune-wheel";

export function FortuneWheelPage() {
  const [wheelSectors, setWheelSectors] = useState<TSector[]>([]);

  const { $t } = useTranslate();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const isMobile = useMobile();
  const formatMoney = usePlayerMoneyFormatter();

  const { data, refetch } = FortuneWheelContext.useWheelSettings();

  const settings = data?.result;
  const rules = settings?.rules;

  function invalidateWheelState() {
    queryClient.invalidateQueries({ queryKey: ["Lobby.getCurrent"] });
    queryClient.invalidateQueries({ queryKey: ["FortuneWheel.getSettings"] });
  }

  function handleWinAnimationEnd(params: TFortuneWheelSectorParams) {
    emitter.emit("CONGRATS_MODAL_OPEN", {
      message: $t({ defaultMessage: "you won" }),
      value: formatMoney(params.value),
      onTake: () => {
        invalidateWheelState();
        navigate({ to: "/", replace: true });
      },
    });
  }

  function handleDepositAbsent() {
    emitter.emit(
      "WARNING_MODAL_OPEN",
      $t({ defaultMessage: "Make a deposit before spinning the wheel of fortune" }),
    );
  }

  useEffect(() => () => void refetch(), []);

  useEffect(() => {
    if (!settings) return;

    const sectors = settings.sectors;
    if (sectors) {
      setWheelSectors(repeatSectors(sectors, WHEEL_SECTORS_REPEAT_COUNT));
    }

    if (settings.state === "make_deposit") {
      handleDepositAbsent();
    }
  }, [settings]);

  return (
    <div className="fortune-wheel-page">
      <Header />
      <main className="flex-grow pb-4 overflow-hidden relative">
        <div className="lg:max-w-screen-3xl lg:px-5 lg:mt-5 px-3 mx-auto mt-3 mobile-only:absolute">
          <Button type="primary" onClick={() => navigate({ to: "/" })}>
            {$t({ defaultMessage: "Back" })}
          </Button>
        </div>

        <div className="lg:flex-center lg:gap-15 lg:mt-24 lg:px-5 mt-12 px-3 relative">
          <div className="flex-center isolate w-full relative mobile-only:mt-2">
            {!isMobile && <RulesSectionDesktop rules={rules} />}

            <FortuneWheel
              className="lg:w-[50.625rem] mobile-only:max-w-96 w-full box-shadow-[0px 4px 10px #f00]"
              sectors={wheelSectors}
              onWinAnimationEnd={handleWinAnimationEnd}
            />
          </div>
        </div>

        {isMobile && (
          <div className="px-3 isolate w-full">
            <RulesSectionMobile rules={rules} />
          </div>
        )}
      </main>
      <Footer className="mt-3" />

      <CongratsModal />
    </div>
  );
}
