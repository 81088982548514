import { useTranslate } from "@lobby/ocb-intl";

import { Lobby } from "@entities/lobby";
import { Player } from "@entities/player";
import { useMobile } from "@shared/lib";
import { Button } from "@shared/ui";

interface ILogoutButtonProps {
  className?: string;
}

export function LogoutButton({ className }: ILogoutButtonProps) {
  const { $t } = useTranslate();
  const isMobile = useMobile();
  const logoutMutation = Player.useLogOut();
  const lobby = Lobby.useLobby();
  const isLogoutButtonEnabled = lobby?.data?.buttons?.logout;

  return (
    isLogoutButtonEnabled && (
      <div className={className}>
        <Button
          className="font-bold w-full"
          type={isMobile ? "secondary" : "ghost"}
          onClick={logoutMutation.mutate}
        >
          {$t({ defaultMessage: "Sign out" })}
        </Button>
      </div>
    )
  );
}
