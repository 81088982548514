import { useTranslate } from "@lobby/ocb-intl";
import { clsx } from "clsx";

import { Player, usePlayerMoneyFormatter } from "@entities/player";

export function ProfileBalanceDetails({ className }: { className?: string }) {
  const { $t } = useTranslate();
  const formatMoney = usePlayerMoneyFormatter();

  const { data: data } = Player.useBalances();

  const balances = data?.balance;
  const wager = data?.wager;

  return (
    <div
      className={clsx(
        className,
        "lg:text-sm text-steel-blue font-bold text-xs uppercase dark:text-gray",
      )}
    >
      <div className="flex-c-sb gap-2">
        <span className="whitespace-nowrap">{$t({ defaultMessage: "wager" })}</span>
        <span>{formatMoney(data?.wager?.remaining ?? 0)}</span>
      </div>
    </div>
  );
}
